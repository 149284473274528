body.is-fixed {
  position: fixed;
  left: 0;
  width: 100%;
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.react-datepicker {
  font-size: 1em !important;
}
.react-datepicker__header {
  padding-top: 0.8em !important;
}
.react-datepicker__month {
  margin: 0.4em 1em !important;
}
.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em !important;
  line-height: 1.9em !important;
  margin: 0.166em !important;
}

.react-datepicker__current-month {
  font-size: 1em !important;
}
.react-datepicker__navigation {
  top: 0.8em !important;
  line-height: 1.7em !important;
  border: 0.45em solid transparent !important;
  width: 0.8em !important;
  height: 0.8em !important;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc !important;
  left: 1em !important;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc !important;
  right: 1em !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker__close-icon {
  right: 36px !important;

  &::after {
    background-color: transparent !important;
    color: black !important;
    display: flex !important;
    align-items: center !important;
    padding-top: 4px !important;
    font-size: 24px !important;
  }
}
