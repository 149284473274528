@font-face {
    font-family: 'gotham';
    src: /*url('../fonts/gotham-light-webfont.woff2') format('woff2'),*/
         url('../fonts/gotham-light-webfont.woff') format('woff'),
         url('../fonts/gotham-light-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'gotham';
    src: url('../fonts/gotham-bookitalic-webfont.woff2') format('woff2'),
         url('../fonts/gotham-bookitalic-webfont.woff') format('woff'),
         url('../fonts/gotham-bookitalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'gotham';
    src: /*url('../fonts/gotham-book-webfont.woff2') format('woff2'),*/
         url('../fonts/gotham-book-webfont.woff') format('woff'),
         url('../fonts/gotham-book-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'gotham';
    src: /*url('../fonts/gotham-book-webfont.woff2') format('woff2'),*/
         url('../fonts/gotham-book-webfont.woff') format('woff'),
         url('../fonts/gotham-book-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'gotham';
    src: url('../fonts/gotham-medium-webfont.woff2') format('woff2'),
         url('../fonts/gotham-medium-webfont.woff') format('woff'),
         url('../fonts/gotham-medium-webfont.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'gotham';
    src: url('../fonts/gotham-bold-webfont.woff2') format('woff2'),
         url('../fonts/gotham-bold-webfont.woff') format('woff'),
         url('../fonts/gotham-bold-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}