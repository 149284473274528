$baseFontSize: 10;
@function rem($pixels, $context: $baseFontSize) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}

.share {
  position: relative;
  display: flex;
  align-items: center;
  color: #353535;
  font-size: rem(13);
  margin-top: rem(20);
  font-weight: 400;
  border: none;
  background: transparent;
  cursor: pointer;
  margin-bottom: rem(30);

  @media screen and (min-width: 768px) {
    margin-top: 0;
  }

  &__icon {
    margin-right: 12px;
  }

  &.copied {
    &::after {
      content: attr(title);
      position: absolute;
      top: calc(100% + 8px);
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      border-radius: 3px;
      padding: 5px 5px;
      background: #f1f1f1;
      white-space: nowrap;
      color: #353535;
      z-index: 1;

      &:hover {
        pointer-events: none;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 21px;
      left: 45%;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #f1f1f1;
    }
  }
}
